<template>
<CSLayout :type="'qna'">
  <div class="qna-detail-content flex flex-col items-center">
    <div class="item flex flex-row w-full">
      <p class="label flex justify-center items-center">상담분류</p>
      <p class="value category flex-1">{{ data.hasQnaCategory.title }}</p>
    </div>

    <div class="item flex flex-row w-full">
      <p class="label flex justify-center items-center">이름</p>
      <p class="value flex-1">{{ data.hasWriter.name }}</p>
    </div>

    <div v-if="data.hasWriter.phone" class="item flex flex-row">
      <p class="label flex justify-center items-center">연락처</p>
      <p class="value flex-1">{{ data.hasWriter.phone }}</p>
    </div>

    <div class="item flex flex-row w-full">
      <p class="label flex justify-center items-center">이메일</p>
      <p class="value flex-1">{{ data.hasWriter.email }}</p>
    </div>

    <div class="item flex flex-row w-full">
      <p class="label flex justify-center items-center">제목</p>
      <p class="value title flex-1">{{ data.title }}</p>
    </div>

    <div class="item content flex flex-row w-full">
      <p class="label flex justify-center items-center">문의내용</p>
      <p class="value flex-1">{{ data.contents }}</p>
    </div>

    <div class="item flex flex-row w-full" v-if="data.hasQnaFiles.length > 0">
      <p class="label flex justify-center items-center">첨부파일</p>
      <button @click="download" class="flex justify-center items-center">첨부파일 다운로드</button>
    </div>

    <div v-if="data.hasQnaComments.length > 0" class="item answer flex flex-row w-full">
      <p class="label flex justify-center items-center">답변내용</p>
      <p class="value answer flex-1">{{ data.hasQnaComments[0].contents }}</p>
    </div>

    <div class="button-group flex flex-row items-center">
      <button v-if="isEditable" @click="goToUpdate" class="edit">수정하기</button>
      <button @click="goBack">목록으로</button>
    </div>
  </div>
</CSLayout>
</template>

<script>
import CSLayout from '@/components/CSLayout.vue';
import services from '@/services';
import axios from 'axios';
import { mapGetters } from "vuex";

export default {
  name: 'QnaDetail',
  components: {
    CSLayout,
  },
  data() {
    return {
      data: {
        title: '',
        contents: '',
        hasQnaCategory: {
          title: ''
        },
        hasWriter: {
          name: '',
          phone: '',
          email: '',
          id: 0,
        },
        hasQnaComments: []
      },
    }
  },
  computed: {
    ...mapGetters("user", ["getUser"]),
    isEditable() {
      if (this.data.hasWriter.id == this.getUser.id) { // 동일 사용자
        return true;
      } else if (this.getUser.isAdmin) { // 관리자
        return true;
      }

      return false;
    },
  },
  mounted() {
    const {id} = this.$route.params;
    this.getQna(id);
  },
  methods: {
    getQna: async function(id) {
      if (!id) {
        return;
      }
      const data = await services.qnaDetail(id);
      this.data = data;
    },
    goToUpdate() {
      const {id} = this.$route.params;
      this.$router.push({name: 'QnaUpdate', params: {id}});
    },
    goBack() {
      this.$router.go(-1);
    },
    async download() {
      const BASE_URL = (process.env.VUE_APP_IS_LOCAL_SERVER == 1 ? process.env.VUE_APP_API_BASEURL_LOCAL : process.env.VUE_APP_API_BASEURL_SERVER);
      const urls = this.data.hasQnaFiles.map(f => {
        const {file_id, has_file: {hashName}} = f;
        const url = `${BASE_URL}/download/${file_id}`;
        return {url, name: hashName};
      });
      
      await Promise.all(urls.map(url => this.downloadImage(url)))
    },
    async downloadImage(image) {
      const res = await axios.get(image.url, {responesType: 'blob'});
      const blobUrl = window.URL.createObjectURL(new Blob([res.data], { type: res.headers['content-type'] }));
      const link = document.createElement('a');
      
      link.href = blobUrl;
      link.setAttribute('download', image.name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  },
}
</script>

<style scoped lang="scss">
.qna-detail-content {
  .item {
    border-bottom: 1px solid #e6e6e6;
    padding: 24px 0;

    &.content {
      min-height: 345px;
    }

    &.answer {
      min-height: 335px;
    }

    .label {
      width: 120px;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.84;
      letter-spacing: -0.8px;
      text-align: left;
      color: #b0b0b0;
    }

    .value {
      font-size: 16px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.84;
      letter-spacing: -0.8px;
      text-align: left;
      color: #262626;

      &.category {
        font-weight: 500;
        color: #283aef;
      }

      &.title {
        font-weight: 500;
        color: #2e2e2e;
      }

      &.answer {
        color: #2c2c2c;
      }
    }

    button {
      width: 132px;
      height: 25px;
      padding: 6px 6px 5px 7px;
      border-radius: 3px;
      background-color: #283aef;

      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.96;
      letter-spacing: -0.7px;
      text-align: left;
      color: #fff;
    }
  }

  .button-group {
    margin-top: 57px;

    button {
      width: 192px;
      height: 55px;
      background-color: #c7c7c7;

      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.84;
      letter-spacing: -0.8px;
      text-align: center;
      color: #fff;

      &:first-child {
        margin-right: 16px;
      }

      &.edit {
        background-color: #283aef;
      }
    }
  }
}

@media (max-width: 639px) {
  .qna-detail-content {
    .item {
      padding: 15px 0;
      width: 100%;
      .label {
        font-size: 12px;
        width: 50px;
        justify-content: flex-end;
        margin-right: 15px;
      }
      .value {
        font-size: 11px;
      }
      &.content,
      &.answer {
        min-height: 100px;
      }
    }
    .button-group {
      flex-direction: column;
      button {
        &:first-child {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>